<template>
  <div class="login" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="login-bg">
      <div class="login-content">
        <div class="form-content">
          <!-- 登录框 -->
          <div class="form-box clearfix">
            <div class="logo"><img src="../../assets/img/login/logo.png" /></div>
            <el-form
              size="mini"
              :model="dataForm"
              :rules="dataRule"
              ref="dataForm"
              @keyup.enter.native="dataFormSubmit()"
            >
              <el-form-item class="area-code">
                <el-select value="+86" >
                  <el-option label="中国+86" value="+86"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="user-name" prop="userName">
                <el-input v-model="dataForm.userName" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item class="password" prop="password">
                <el-input v-model="dataForm.password" :type="showPassword ? 'text' : 'password'" placeholder="请输入登录密码" >
                  <span slot="suffix" @click="showPassword = !showPassword">
                    <icon-svg name="show-password"></icon-svg>
                  </span>
                </el-input>
              </el-form-item>
            </el-form>
            <div>
              <el-checkbox v-model="checked" class="diy-checkbox">记住我</el-checkbox>
              <i>忘记密码</i>
            </div>
            <el-button
              class="login-btn-submit"
              type="primary"
              @click="dataFormSubmit()"
              size="medium"
            >登录</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="请选择租户" :visible.sync="dialog" width="30%">
      <div class="radio-box">
        <el-radio-group v-model="tenantId">
          <el-radio v-for="(item, i) in tenants" :key="i" :label="i">{{item.tenantName}}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" :loading="setTenantState" @click="setTenant">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      append-to-body
      title="重置密码"
      :show-close="false"
      :visible.sync="password.show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="resetPassword"
    >
      <el-form
        :model="password.data"
        status-icon
        :rules="password.rules"
        ref="passwordForm"
        label-width="100px"
      >
        <el-form-item label="密码：" prop="pass">
          <el-input type="password" v-model="password.data.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPass">
          <el-input type="password" v-model="password.data.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePassword">取 消</el-button>
        <el-button type="primary" :loading="password.loading" @click="resetPassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      } else {
        if (value.length < 6 || value.length > 16) {
          return callback(new Error('密码长度为6~16位字符'))
        }

        if (/(\w)\1\1/g.test(value)) {
          return callback(new Error('密码设置过弱，请重新设置'))
        }

        let reg = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])')
        if (!reg.test(value)) {
          return callback(new Error('密码必须含有数字+字母'))
        }

        const LxStr = (str) => {
          const arr = str.split('')
          let flag = false
          for (let i = 1; i < arr.length - 1; i++) {
            const firstIndex = arr[i - 1].charCodeAt()
            const secondIndex = arr[i].charCodeAt()
            const thirdIndex = arr[i + 1].charCodeAt()
            if ((thirdIndex - secondIndex === 1) && (secondIndex - firstIndex === 1)) {
              flag = true
            }
          }
          return flag
        }

        if (LxStr(value)) {
          return callback(new Error('密码不允许出现连续3位递增字符'))
        }

        if (this.password.data.checkPass !== '') {
          this.$refs.passwordForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.password.data.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      setTenantState: false,
      dialog: false,
      password: {
        show: false,
        loading: false,
        data: {},
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
      },
      tenant: '',
      tenantId: '',
      tenants: [],
      showPassword: false,
      dataForm: {
        userName: '',
        password: '',
        captcha: ''
      },
      dataRule: {
        userName: [
          { required: true, message: '帐号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      captchaPath: '',
      checked: false,
      fullscreenLoading: false
    }
  },
  computed: {},
  mounted () {
    this.getAccount()
  },
  methods: {
    getAccount () {
      var curTime = new Date().getTime()
      var data = localStorage.getItem('information')
      var dataObj = JSON.parse(data)
      if (data) {
        if (curTime - dataObj.time > 1000 * 60 * 60 * 24 * 7) {
          localStorage.removeItem('information')
        } else {
          this.dataForm.userName = dataObj.data.userName
          this.dataForm.password = dataObj.data.password
          this.checked = true
        }
      }
    },
    setAccount () {
      if (this.checked) {
        var curTime = new Date().getTime()
        localStorage.setItem(
          'information',
          JSON.stringify({ data: this.dataForm, time: curTime })
        )
      }
    },
    setTenant () {
      // userId
      let userId = this.tenants[this.tenantId].id
      this.ActionsUserId(userId)
      // tenantId
      this.ActionsTenantId(this.tenants[this.tenantId].tenantId)
      this.setTenantState = true
      this.$http({
        url: this.$http.adornUrl('/p/tenant/checkTenant'),
        method: 'post',
        data: {
          id: this.tenants[this.tenantId].tenantId
        }
      }).then(res => {
        this.setTenantState = false
        // 初始化sessionStorage数据
        this.$initSessionStorageData.init()
        this.$router.replace({ path: '/user-home' })
      })
    },
    dataFormSubmit () {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.fullscreenLoading = true
          this.$http({
            url: this.$http.adornUrl('/gateway/auth'),
            // url: 'http://10.204.133.125/gateway/auth',
            method: 'post',
            data: {
              username: this.dataForm.userName,
              password: this.dataForm.password,
              loginType: 'pc',
              clientSource: 4
            }
          })
            .then(({ data }) => {
              if (data && data.code === 'C0000') {
                if (data.result.isPwdExpired) {
                  this.password.show = true
                } else {
                  this.setAccount()
                  console.log(process.env.VUE_APP_DOADMIN)
                  this.$cookie.set('token', data.result.token, {
                    domain: process.env.VUE_APP_DOADMIN
                  })
                  let accountId = data.result.loginAuthDto.accountId
                  this.ActionsLoginId(accountId)
                  if (data.result.loginAuthDto.isAdmin) {
                    this.$http({
                      url: this.$http.adornUrl('/p/user/getUsersByAccountId'),
                      method: 'get',
                      params: {
                        id: accountId
                      }
                    }).then(res => {
                      let userId = res.data.result[0].id
                      this.ActionsUserId(userId)
                    })
                    // 初始化sessionStorage数据
                    this.$initSessionStorageData.init()
                    this.$router.replace({ path: '/home' })
                    return
                  } else {
                    this.$http({
                      url: this.$http.adornUrl('/p/user/getUsersByAccountId'),
                      method: 'get',
                      params: {
                        id: accountId
                      }
                    }).then(res => {
                      if (res.data && res.data.code === 'C0000') {
                        if (!res.data.result.length) {
                          this.$message.error('当前用户没有租户信息')
                        } else if (res.data.result.length === 1) {
                          this.tenantId = 0
                          this.tenants = res.data.result
                          this.setTenant()
                        } else {
                          this.dialog = true
                          this.tenants = res.data.result
                        }
                      } else {
                        this.$message.error(res.data.message)
                      }
                    })
                  }
                }
              }
              this.fullscreenLoading = false
            })
            .catch(() => {
              this.fullscreenLoading = false
            })
        }
      })
    },
    closePassword () {
      this.$refs.passwordForm.resetFields()
      this.password.show = false
      this.$http({
        url: this.$http.adornUrl('/p/user/logout'),
        method: 'post',
        data: this.$http.adornData()
      }).finally(() => {
        this.$router.go(0)
      })
    },
    resetPassword () {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.loading = true
          let data = {
            username: this.dataForm.userName,
            password: this.dataForm.password,
            newPassword: this.password.data.pass,
            confirmPassword: this.password.data.checkPass
          }
          this.$http({
            url: this.$http.adornUrl('/p/loginAccount/updatePwdByAccount'),
            method: 'post',
            data
          }).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message.success(data.message)
              setTimeout(() => {
                this.closePassword()
              }, 1000)
            } else {
              this.$message.error(data.message)
            }
          }).catch((err) => {
            console.error(err)
            this.$message.error('系统繁忙，请稍后再试')
          })
        }
      })
    },
    ...mapActions('user', [
      'ActionsLoginId',
      'ActionsUserId',
      'ActionsTenantId',
      'ActionsUpdateName'
    ])
  }
}
</script>

<style lang='scss'>
.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
}
.clearfix {
  zoom: 1;
}
html,
body {
  height: 100%;
  width: 100%;
}
.login {
  display: table;
  width: 100%;
  height: 100%;
  _position: relative;
  .login-bg {
    vertical-align: middle;
    display: table-cell;
    text-align: center;
    background: #008DFF;
    _position: absolute;
    _top: 50%;
    _left: 50%;
  }
  .login-content {
    width: 442px;
    height: 439px;
    margin: auto;
    _position: relative;
    _top: -50%;
    _left: -50%;
    .form-content {
      position: relative;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;
      .form-box {
        width: 100%;
        height: 100%;
        padding: 40px 60px 60px;
        background: #fff;
        .logo {
          width: 100%;
          height: 70px;
          margin-bottom: 40px;
          text-align: center;
        }
        form {
          display: block;
          margin: auto;
          font-size: 14px;
        }
        form + div {
          text-align: left;
          margin: 0 auto 30px;
          i {
            float: right;
            color: #4798fb;
            font-style: normal;
            font-size: 14px;
            cursor: pointer;
            padding-top: 4px;
          }
        }
        .area-code {
          display: inline-block;
          width: 96px;
          margin-right: 30px;
        }
        .user-name {
          display: inline-block;
          width: 196px;
        }
        .password {
          .el-input__suffix-inner {
            display: inline-block;
            .icon-svg {
              width: 20px;
              height: 20px;
              vertical-align: middle;
              cursor: pointer;
            }
          }
        }
        .el-form-item {
          margin-bottom: 40px;
        }
        .el-input__inner {
          border-radius: 0;
          border-top: none;
          border-left: none;
          border-right: none;
          outline: none;
          font-size: 14px;
          color: #aaa !important;
        }
        .el-checkbox__label {
          padding-left: 6px;
          font-size: 14px;
          color: #aaa;
        }
        .el-input.is-active .el-input__inner, .el-input__inner:focus {
          box-shadow: none;
        }
        .diy-checkbox {
          .el-checkbox__inner {
            width: 21px;
            height: 20px;
            border: none;
            background: url('../../assets/img/login/checkbox.png') no-repeat;
          }
          &.is-checked {
            .el-checkbox__inner {
              background-image: url('../../assets/img/login/checkbox-s.png');
            }
          }
        }
        button {
          width: 100%;
          height: 30px;
          padding: 0;
          line-height: 30px;
          background: #008DFF;
        }
      }
    }
  }
}
.radio-box {
  .el-radio {
    display: block;
    line-height: 40px;
  }
  .el-radio + .el-radio {
    margin-left: 10px;
  }
}
.resetPassword {
  .el-dialog__body {
    min-height: 0;
  }
}
</style>
